import React, { useEffect, useState } from 'react'
import { config } from '@/lib/config'
import getProfileByName from '@/lib/utils/admin/server/profile/getProfileByName'
import getUserOptional from '@/lib/utils/admin/getUserOptional'
import { useUserAuth } from '@/lib/utils/hooks/useUserAuth'
import ProfileHomeContainer from '@/components/profile/profile-home-container'
import getMessage from '@/lib/utils/getMessage'
import isAdminUser from '@/lib/utils/isAdminUser'
import { getCacheDate } from '@/lib/utils/getCacheDate'
import PostArray from '@/components/profile/post/post-array'
import getRecordArraySwr from '@/lib/utils/admin/server/record/getRecordArraySwr'
import HeaderBar from '@/components/templateux/header-bar/header-bar'
import SvgJsx from '@/components/templateux/svg/svg-jsx'
import { hasData } from '@/lib/utils/hasData'
import { useRouter } from 'next/router'
import getScheduleByName from '@/lib/utils/admin/server/profile/getScheduleByName'
import getUserIp from '@/lib/utils/admin/getUserIp'


export async function getStaticProps(context) {

  //OPTIONAL USER LOOKUP
  const {
    user 
  } = await getUserOptional(context.req)


    //Get IP address
    const {ip, cookie} = await getUserIp(context)


  //RETURN DATA FROM A FUNCTION USED BY THIS AND THE API
  const ssr_data = 
      await getProfileByName(
            "profile" //table_name: string
          , "select_by_name" //path_name: string
          , context?.params?.user_name //user_name: string
          , user["id"].toString() //user_id: string
          , ip //ip: string
          , cookie //cookie: string
        )

        
        const ssr_data_posts = 
        await getRecordArraySwr(
              "post" //table_name: string
            , "select_by_user_id" //path_name: string
            , ssr_data?.id //user_id: string
            , "0" //my_id:string
            , "1" //page: string
            , "RAND" //sort: string
            , config.itemdir //dir: string
            , '' //filter: string
        )
          
  return { 
      props: { 
        ssr_data: ssr_data
        ,ssr_data_posts: ssr_data_posts
        ,params: context?.params
        ,navtype: 'posts'
        ,helptype: 'content'
        ,nodata: getMessage("noresults")
      } 
      ,revalidate: 300, //86400 In seconds
    }
}

export async function getStaticPaths() {

  return {
    paths: [],
    fallback: 'blocking',
  }
}

function ProfileHomePage({ ssr_data
                          , ssr_data_posts
                          , params
                          , navtype 
                          , helptype
                          , nodata
                        }) {


  //PRELOAD SSR DATA
  const router = useRouter()
  const { userAuthData, isUserAuthLoading, isUserAuthError } = useUserAuth()
  const [view_mode,set_view_mode] = useState(navtype)
  const [is_searching, set_is_searching] = useState(false)
  const [search_text,set_search_text] = useState('')
  const [filter_text,set_filter_text] = useState('')


  const [change_cache,set_change_cache] = useState(null)
  const [display_view,set_display_view] = useState(navtype)
  useEffect(()=>{set_display_view(navtype)},[navtype])

  useEffect(()=>{set_search_text(router?.query?.search?.toString())},[router])

  //slow routing from next.js query parsing

  const submitSearch = (e) => {
    e.preventDefault()
    set_filter_text(search_text)
    router.push({
      pathname: `/${ssr_data?.user_name}`,
      query: { search: search_text}
    }, 
    undefined, { shallow: true }
    )
  }

  const clearSearch = (e) => {
    e.preventDefault()
    set_filter_text('')
    set_search_text('')
    router.push({
      pathname: `/${ssr_data?.user_name}${navtype=="audio" ? "/audio" : ""}`,
      query: { }
    }, 
    undefined, { shallow: true }
    )
  }

  const [cache, set_cache] = useState(getCacheDate())
  const [isOpen,set_isOpen] = useState(false)

  const closeModal = () => {
      set_isOpen(false)
  }


      //Admin Lookup
      const [isAdmin,set_isAdmin] = useState(false)
      useEffect(()=>{
        set_isAdmin(isAdminUser( ssr_data?.id            //user_id
                                ,ssr_data?.bol_staff_help    //bol_staff_help
                                ,ssr_data?.staff_json        //staff_json
                                ,ssr_data?.bol_admin_help    //bol_admin_help
                                ,userAuthData?.id?.toString()            //my_id
                                ,userAuthData?.bol_admin?.toString()    //global_admin
                                ,helptype
                                ))
      },[
         ssr_data
        ,userAuthData
        ,helptype
      ])


  return (<>
    <ProfileHomeContainer
        ssr_data={ssr_data}
        params={params}
        navtype={navtype}
        helptype={helptype}
        display_view={display_view}
        set_display_view={set_display_view}
        change_cache={change_cache}
        set_change_cache={set_change_cache}
    >

        <HeaderBar
          headertitle={<form
            onSubmit={(e)=> submitSearch(e)}
          >
          <div className="flex text-sm font-base w-full group cursor-pointer">
            <input 
              className={`transition-all duration-500 border rounded-l-md border-gray-700 group-hover:border-white bg-gray-700 placeholder-gray-500 text-white px-2
                ${is_searching ? "flex-1" : "flex-0"}
              `}
              onChange={(e)=>{
                  //set_is_searching(e.target.value.length > 0)
                  set_search_text(e.target.value)
                }}
              onFocus={(e)=>{set_is_searching(true)}}
              onBlur={(e)=>{set_is_searching(e.target.value.length > 0)}}
              defaultValue={search_text}
              placeholder={`Search`}
            />
            
            <div 
              className="border border-gray-700 group-hover:border-white  p-2 rounded-r-md"
              onClick={(e)=> submitSearch(e)}
              role="button"
              tabIndex={0}
              onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                submitSearch(e)
              }}}
            >
              <SvgJsx 
                type={'fill'}
                icon={'search-sm'}
                className={`h-5 w-5`}
                title={'Search'}
              />
            </div>

            {search_text?.length > 0 &&
            <div 
              className=" hover:text-white p-2 "
              onClick={(e)=> clearSearch(e)}
              onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                clearSearch(e)
              }}}
              role="button"
              
              tabIndex={0}
            >
              <SvgJsx 
                type={'fill'}
                icon={'x-sm'}
                className={`h-5 w-5`}
                title={'Clear'}
              />
            </div>
            }
          </div>
        </form>
            
          }
          show_rss={false}
          rss_link={`/${ssr_data?.user_name?.toLowerCase()}/audio/podcast.rss`}
          rss_text={'rss'}
          action_button={<div onClick={()=>confirm('Coming soon!')}>Sort</div>}
          show_action_button={false}
        />

      
      <PostArray 
                    itemdata={ssr_data_posts}
                    isLoading={ false}
                    isError={ false }
                    userdata={userAuthData}
                    ownerdata={ssr_data}
                    display_view={display_view}
                    set_display_view={set_display_view}
                    cache={cache}
                    set_cache={set_cache}
                    slugfilter={''}
                    placeholderdata={[
                    ]}
                    nodata={nodata}
                    navtype={navtype}
                    helptype={helptype}
                    isAdmin={isAdmin}
                    slug={''}
                    title={'All posts'}
                    swr_url={`/api/private/post/select?option=user_id&id=${ssr_data?.id}&cache=${cache}${hasData(filter_text) ? `&q=tag:${filter_text}` : ''}`}
                  />

    </ProfileHomeContainer>

    </>

      )


  }
  

  export default ProfileHomePage